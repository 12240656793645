'use strict';

$.validator.addMethod("validEmail", function(value, element)
{
    if(value == '')
        return true;
    var temp1;
    temp1 = true;
    var ind = value.indexOf('@');
    var str2=value.substr(ind+1);
    var str3=str2.substr(0,str2.indexOf('.'));
    if(str3.lastIndexOf('-')==(str3.length-1)||(str3.indexOf('-')!=str3.lastIndexOf('-')))
        return false;
    var str1=value.substr(0,ind);
    if((str1.lastIndexOf('_')==(str1.length-1))||(str1.lastIndexOf('.')==(str1.length-1))||(str1.lastIndexOf('-')==(str1.length-1)))
        return false;
    var str = /(^[a-zA-Z0-9]+[\._-]{0,1})+([a-zA-Z0-9]+[_]{0,1})*@([a-zA-Z0-9]+[-]{0,1})+(\.[a-zA-Z0-9]+)*(\.[a-zA-Z]{2,3})$/;
    temp1 = str.test(value);
    return temp1;
}, "Please enter valid email.");

$(document).on('click', '#mauticform_input_newsletterdasitoweb_submit', function(e)
{
    // e.preventDefault();
    if( !$("#mauticform_sitowebdropticketnewsletter").valid() )
    {
        return false;
    }
});

$("#mauticform_sitowebdropticketnewsletter").validate({
    onkeyup: false,
    rules: {
        'mauticform[email]': {
            required: true,
            validEmail: true,
        },
    },
    messages: {
        'mauticform[email]': {
            required: "Inserisci il tuo indirizzo email",
            validEmail: "Inserisci un indirizzo email valido",
            email: "Inserisci un indirizzo email valido",
        },
    },
    errorElement : 'div',
    errorClass: 'mauticform-errormsg',
    errorPlacement: function(error, element) {
        $('#mauticform_sitowebdropticketnewsletter').append(error);
    }
});

$(document).on('click', 'input', function(){
    $(this).next('.mauticform-errormsg').hide();
});
// END newsletter



if (typeof MauticFormCallback == 'undefined') {
    var MauticFormCallback = {};
}

MauticFormCallback = {
    'newsletterdasitoweb': {
        onResponse: function(response) {

            if( response.success )
            {
                $('.mauticform-innerform').fadeOut(function(){
                    $('#mauticform_sitowebdropticketnewsletter_message').html('<h3>Grazie per esserti registrato.</h3>').fadeIn();
                });
            }
        }
    }
};
